import axios from 'axios'

function getFaqListing(payload) {
  return axios.get('faq', {
    params: payload
  })
}

function switchFaqStatus(id) {
  return axios.put(`faq/active-deactive/${id}`)
}
function addFaq(data) {
  return axios.post('faq', data)
}

function getFaqById(id) {
  return axios.get(`faq/${id}`)
}

function deleteFaq(id) {
  return axios.delete(`faq/${id}`)
}

function updateFaq(id, data) {
  return axios.put(`faq/${id}`, data)
}

function getCategoryListing() {
  return axios.get('category')
}

function addCategory(name) {
  return axios.post('category', name)
}

function getCategoryById(id) {
  return axios.get(`category/${id}`)
}

function updateCategory(id, name) {
  return axios.put(`category/${id}`, name)
}

function deleteCategory(id) {
  return axios.delete(`category/${id}`)
}

export default {
  getFaqListing,
  switchFaqStatus,
  addFaq,
  getFaqById,
  deleteFaq,
  getCategoryListing,
  addCategory,
  getCategoryById,
  updateCategory,
  updateFaq,
  deleteCategory
}

import { serverMsg } from '@/helpers/constant'

export default function errorResponseHandler(error) {
  let errorData = ''
  if (error.response) {
    errorData = error.response ? error.response.data.message : serverMsg
  } else {
    errorData = error.res ? error.res.data.message : serverMsg
  }
  return errorData
}

<template>
  <div id="app">
    <!-- <h3></h3> -->
    <CategoryAdd v-if="!id && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :category-details="categoryDetails" @on-Submit="createCategory" />

    <!-- <h3>Edit Faq form</h3> -->
    <CategoryAdd v-if="id && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :category-details="categoryDetails" @on-Submit="updateCategory" />
  </div>
</template>

<script>
import faqService from '@/services/faq/faq.service'
import { resConditionCheck } from '@/helpers/constant'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CategoryAdd from './CategoryAdd.vue'

export default {
  name: 'CategoryAddEdit',
  components: { CategoryAdd },
  data() {
    return {
      isLoaded: false,
      categoryDetails: {},
      id: this.$route.params.id ? this.$route.params.id : '',

    }
  },

  watch: {
    async $route() {
      if (this.$route.name === 'category-add') {
        this.id = ''
        this.categoryDetails = ""
        this.isLoaded = false
      } else if (this.$route.name === 'category-edit') {
        this.id = this.$route.params.id
        await this.getCategoryById(this.$route.params.id)
        this.isLoaded = true
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.getCategoryById(this.$route.params.id);
    }
  },

  methods: {
    async createCategory(categoryName) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        this.$refs.simpleRules.loading = true

        faqService.addCategory(categoryName).then(res => {
          this.$refs.simpleRules.loading = false

          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push('/faq/category/list')
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    },

    async getCategoryById(id) {
      await faqService.getCategoryById(id).then(res => {
        this.isLoaded = true;
        if (resConditionCheck(res.status) && res.data.data) {
          this.categoryDetails = res.data.data
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },

    async updateCategory(categoryForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        this.$refs.simpleRules.loading = true
        faqService.updateCategory(this.id, categoryForm).then(res => {
          this.$refs.simpleRules.loading = false
          this.$router.push('/faq/category/list')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    }

  }
}
</script>

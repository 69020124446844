export const validatorPositive = value => {
	if (value >= 0) {
		return true;
	}
	return false;
};

export const validatorPassword = password => {
	/* eslint-disable no-useless-escape */
	const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
	/* eslint-enable no-useless-escape */
	const validPassword = regExp.test(password);
	return validPassword;
};

export const validatorCreditCard = creditnum => {
	/* eslint-disable no-useless-escape */
	const cRegExp = /^(?:3[47][0-9]{13})$/;
	/* eslint-enable no-useless-escape */
	const validCreditCard = cRegExp.test(creditnum);
	return validCreditCard;
};

export const validatorUrlValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true;
	}
	/* eslint-disable no-useless-escape */
	// const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
	const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

	/* eslint-enable no-useless-escape */
	return re.test(val);
};

export const patternValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true;
	}
	/* eslint-disable no-useless-escape */
	// const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
	const re = /^(?! )(?!.* $)[a-zA-Z\u00C0-\u017F0-9,._=+;'*!$%^&#@:"{}\[\]?/: ()-]+$/;
	/* eslint-enable no-useless-escape */
	return re.test(val);
};

export const noSpecialValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true;
	}
	/* eslint-disable no-useless-escape */
	// const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
	const re = /[A-Za-z0-9_-]+/;
	/* eslint-enable no-useless-escape */
	return re.test(val);
};

export const noWhiteSpaceValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true;
	}
	const re = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
	return re.test(val);
}

export const alphaNumericValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true
	}
	// const re = /^[a-z0-9]+$/i;
	const re = /^[a-zA-Z0-9_\s]+$/
	return re.test(val);
}

export const domainNameValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true
	}
	const regex = /(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/
	return regex.test(val);
}

export const noWhiteSpaceAllow = val => {
	if(val=== undefined || val === null || val.length === 0) {
		return true;
	}
	const re = /^\S*$/;
	return re.test(val);
}